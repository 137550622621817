<template>
  <v-container>
    <v-row>
      <v-col lg="6">
        <div class="mb-10">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Xичээл сонгосон xүүxдүүдийн жагсаалт
          </h5>
          <p class="text-sm text-body mb-0">
            Энэxүү xуудас нь xичээл сонгосон xүүxдүүдийн мэдээллий xаруулна.
          </p>
        </div>
      </v-col>
      <v-col>
        <v-select
          :items="lessonCategories"
          item-value="id"
          item-text="name2"
          return-object
          v-model="selectedLessonCategory"
          placeholder="Хичээл сонгох"
        ></v-select>
      </v-col>
    </v-row>
    <v-card-text class="px-0 py-0 scoreTables mt-4">
      <v-simple-table v-if="students && selectedLessonCategory">
        <template v-slot:default>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th
                v-for="(assignment, lindex) in assignments"
                :key="'lesson' + lindex"
              >
                {{ assignment.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, sindex) in students" :key="'student' + sindex">
              <td class="text-center" style="width: 1%">{{ sindex + 1 }}</td>
              <td>
                {{ student.firstName }},
                <span style="color: #aaa">{{ student.lastName }}</span>
              </td>

              <td
                class="text-center"
                v-for="(assignment, lindex) in assignments"
                :key="'lessonBody' + lindex"
                :style="
                  assignment.results[student.id]
                    ? 'background-color: #ffeb3b'
                    : ''
                "
              >
                <span v-if="assignment.results[student.id]">{{
                  getResultPercentage(assignment.results[student.id])
                }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { store } from "@/store.js";
export default {
  name: "light-table-transparent",
  data() {
    return {
      actionstatus: null,
      search: "",
      snackbar: false,
      students: null,
      lessonCategories: null,
      selectedLessonCategory: null,
      assignments: null,
    };
  },
  methods: {
    getResultPercentage(result) {
      return ((result.totalScore / result.catScore) * 100).toFixed(2) + "%";
    },
  },
  watch: {
    selectedLessonCategory(val) {
      if (val) {
        fb.db
          .collection("assignmentsdatabase")
          .where("categoryRef", "==", val.ref)
          .where("deleted", "==", false)
          .where("createdBySchoolRef", "==", store.state.userData.school.ref)
          .get()
          .then((docs) => {
   
            this.assignments = [];
            docs.forEach(async (doc) => {
              let assignment = doc.data();
              assignment.ref = doc.ref;
              assignment.id = doc.id;

              await assignment.ref
                .collection("results-simplified")
                .get()
                .then((docs) => {
                  assignment.results = {};
                  docs.forEach((doc) => {
                    let result = doc.data();
                    result.ref = doc.ref;
                    result.id = doc.id;
                    result.catScore = 0;
                    for (var cat of result.categories) {
                      result.catScore = result.catScore + cat.totalScore;
                    }

                    assignment.results[result.id] = result;
                  });
                });

              this.assignments.push(assignment);
            });
            console.log(this.assignments);
          });
      }
    },
  },
  created() {
    fb.db
      .collection("categories")
      .orderBy("name", "asc")
      .onSnapshot((lessons) => {
        this.lessonCategories = [];
        var counter = 1;
        lessons.forEach((lesson) => {
          let eyesh = lesson.data();
          eyesh.ref = lesson.ref;
          eyesh.id = lesson.id;
          eyesh.text = eyesh.name;
          eyesh.name2 =
            counter + ". " + eyesh.name + " - " + eyesh.lessonTypeName;
          eyesh.students = null;
          eyesh.ref
            .collection("lesson-students")
            .where("ppschool", "==", store.state.userData.school.ref.path)
            .get()
            .then((studs) => {
              eyesh.students = [];
              studs.forEach((stud) => {
                let students = stud.data();
                students.docRef = stud.ref;
                students.docId = stud.id;
                eyesh.students.push(students);
              });
            });

          this.lessonCategories.push(eyesh);
          counter++;
        });
      });
    store.state.userData.school.ref
      .collection("students")
      .get()
      .then((students) => {
        this.students = [];
        students.forEach((student) => {
          let stud = student.data();
          stud.ref = student.ref;
          stud.id = student.id;
          this.students.push(stud);
        });
      });
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
